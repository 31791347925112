import { useMedia } from "./use-media"
import { useState, useLayoutEffect } from "react"

export const useViewportWidth = () => {
  const windowWidth = typeof window !== "undefined" ? window.innerWidth : 1200

  const [width, setWidth] = useState(windowWidth)

  useLayoutEffect(() => {
    const handleResize = e => {
      setWidth(e.target.innerWidth)
    }
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  return width
}

export const useViewport = () => {
  const isExtraNarrowPlus = useMedia("(min-width: 28rem)")
  const isNarrowPlus = useMedia("(min-width: 48rem)")
  const isMediumPlus = useMedia("(min-width: 54rem)")
  const isWidePlus = useMedia("(min-width: 70rem)")
  const isExtraWidePlus = useMedia("(min-width: 90rem)")

  return {
    isExtraNarrowPlus,
    isNarrowPlus,
    isMediumPlus,
    isWidePlus,
    isExtraWidePlus,
  }
}
