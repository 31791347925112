/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import Helmet from "react-helmet"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import "./layout.css"
import Nav from "./nav"
import { Footer } from "./footer"
import { MetomicHeadScript } from "../metomic-head-script"
import MetaImage from "../images/meta-image.jpg"
import { LandbotHeadScript } from "../landbot-head-script"

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={() => (
      <>
        <Helmet
          link={[
            {
              rel: "mask-icon",
              href: "/safari-pinned-tab.svg",
              color: "#6772e5",
            },
            {
              rel: "apple-touch-icon",
              size: "180x180",
              href: "/apple-touch-icon.png",
            },
            {
              rel: "icon",
              type: "image/png",
              sizes: "16x16",
              href: "/favicon-16x16.png",
            },
            {
              rel: "icon",
              type: "image/png",
              sizes: "32x32",
              href: "/favicon-32x32.png",
            },
          ]}
          meta={[
            { name: "twitter:card", content: "summary_large_image" },
            { name: "twitter:image", content: MetaImage },
            { name: "og:image", content: MetaImage },
            { name: "og:image:secure_url", content: MetaImage },
            { name: "msapplication-TileColor", content: "#00a300" },
          ]}
        >
          <script>{MetomicHeadScript}</script>
          <script>{LandbotHeadScript}</script>
        </Helmet>

        <Nav />
        {children}

        <Footer />
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
