import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { HeaderJobs } from "../components/header-jobs"
import RemoteImage from "../images/remote.svg"
import Container from "../components/container"
import css from "@emotion/css"
import FlexibleTimeImage from "../images/flexible-hours.svg"
import styled from "@emotion/styled"
import { useViewport } from "../hooks/use-viewport-width"
import { highlight } from "../components/highlight"

import "../components/forms.sass"

const JobsInfoBox = styled.div`
  margin-bottom: 60px;
`

export function JobsLayout({ children }) {
  const viewport = useViewport()
  return (
    <Layout>
      <SEO title="Jobs" />
      <HeaderJobs />

      <Container
        css={css`
          align-items: start;
        `}
      >
        {viewport.isMediumPlus && (
          <div
            css={css`
              min-width: 300px;
              margin-right: 100px;
            `}
          >
            <JobsInfoBox>
              <img
                src={RemoteImage}
                alt="Remote Illustration"
                className="img-responsive"
              />

              <h2
                css={css`
                  text-align: center;
                `}
              >
                Fully remote
              </h2>
            </JobsInfoBox>

            <JobsInfoBox>
              <img
                src={FlexibleTimeImage}
                alt="Remote Illustration"
                className="img-responsive"
              />

              <h2
                css={css`
                  text-align: center;
                `}
              >
                Flexible working hours
              </h2>
            </JobsInfoBox>
          </div>
        )}
        <div
          css={css`
            width: 100%;
          `}
        >
          <div
            css={css`
              margin-bottom: 60px;
            `}
          >
            <h1>We create rich user experience applications</h1>

            <p style={{ fontSize: "20px" }}>
              We value motivated people and we want to hear your ideas.{" "}
              <a css={highlight} href="https://crispyapps.freshteam.com/jobs">
                Join us
              </a>
            </p>
          </div>

          <div style={{ background: "#eee", padding: "10px 20px" }}>
            <h1
              css={css`
                text-align: center;
              `}
            >
              Want to stay in touch with us?
            </h1>
            {children}
          </div>
        </div>
      </Container>
    </Layout>
  )
}
