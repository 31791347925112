import React from "react"
import Logo from "../images/logo.svg"
import Container from "./container"
import { Link } from "gatsby"
import { css } from "@emotion/core"
import styled from "@emotion/styled"
import { Button } from "./button"
import { useViewport } from "../hooks/use-viewport-width"

const Wrapper = styled.div`
  padding: 17px 0;
  font-size: 16px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
`

const MenuItem = styled.span`
  &:hover {
    text-decoration: underline;
  }
`

export default () => {
  const { isExtraNarrowPlus } = useViewport()
  return (
    <Wrapper>
      <Container
        css={css`
          justify-content: space-between;
          height: 100%;
          ${!isExtraNarrowPlus
            ? `flex-direction: column;
          align-items: start;`
            : ""}
        `}
      >
        <Link to="/">
          <img height="35" alt="Logo" src={Logo} />
        </Link>

        <nav
          css={
            !isExtraNarrowPlus
              ? css`
                  width: 100%;
                  display: flex;
                  justify-content: flex-end;
                  align-items: center;
                  margin-top: 20px;
                `
              : null
          }
        >
          <Link to="/jobs" style={{ marginRight: "2rem" }}>
            <MenuItem>Jobs</MenuItem>
          </Link>

          <Link to="/contact">
            <Button>Contact us</Button>
          </Link>
        </nav>
      </Container>
    </Wrapper>
  )
}
