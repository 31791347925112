import { useState, useLayoutEffect } from "react"

export function useMedia(query, defaultState = false) {
  const [state, setState] = useState(defaultState)

  useLayoutEffect(() => {
    const mediaQuery = window.matchMedia(query)
    setState(mediaQuery.matches)

    function handleChange() {
      setState(mediaQuery.matches)
    }

    mediaQuery.addListener(handleChange)

    return () => {
      mediaQuery.removeListener(handleChange)
    }
  }, [query])

  return state
}
