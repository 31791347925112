import React from "react"
import css from "@emotion/css"

import { JobsLayout } from "../../components/jobs-layout"

import "../../components/forms.sass"

const SubscriptionSuccess = () => {
  return (
    <JobsLayout>
      <p
        css={css`
          font-size: 20px;
          text-align: center;
        `}
      >
        Thank you for subscribing. We'll be in touch :)
      </p>
    </JobsLayout>
  )
}

export default SubscriptionSuccess
