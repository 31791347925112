import React from "react"
import { Header, HeaderTitle } from "./header"
import HeaderImage from "../images/jobs.svg"
import css from "@emotion/css"

export const HeaderJobs = () => {
  return (
    <Header
      containerCss={css`
        justify-content: space-between;
        align-items: center;
      `}
      renderText={() => <HeaderTitle>We are hiring</HeaderTitle>}
      renderPicture={() => (
        <img
          css={css`
            max-width: 500px;
          `}
          className="img-responsive"
          alt="Jobs Illustration"
          src={HeaderImage}
        />
      )}
    />
  )
}
