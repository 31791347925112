const LandbotClientId = process.env.GATSBY_LANDBOT_CLIENT_ID || ""

export const LandbotHeadScript = LandbotClientId
  ? `var scr  = document.createElement('script'),
    head = document.head || document.getElementsByTagName('head')[0];
    scr.src = 'https://static.landbot.io/landbot-widget/landbot-widget-1.0.0.js';
    
    scr.onload = function() {
      var scr  = document.createElement('script');
      scr.innerText = "var myLandbot = new LandbotLivechat({index: 'https://landbot.io/u/${LandbotClientId}/index.html',});"
      head.insertBefore(scr, head.firstChild);
    }

    head.insertBefore(scr, head.firstChild);`
  : ""
