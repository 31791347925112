import React from "react"
import css from "@emotion/css"
import Container from "./container"
import styled from "@emotion/styled"
import { useViewportWidth, useViewport } from "../hooks/use-viewport-width"

export const HeaderTitle = styled.h1`
  color: #fff;
  font-weight: 300;
`

export const HeaderSubtitle = styled.div`
  color: #e7fff4;
  font-size: 22px;
  font-weight: 400;
  line-height: 1.4;
`

const Header = ({ renderText, renderPicture, containerCss }) => {
  const width = useViewportWidth()
  const viewport = useViewport()
  const headerPadding = (width - 1920) / 10

  return (
    <header
      css={css`
        position: relative;
        overflow: hidden;
        padding-bottom: ${headerPadding > 0 ? headerPadding : 0}px;
      `}
    >
      <div
        css={css`
          position: absolute;
          height: 100%;
          width: 100%;
          z-index: -1;
          transform: skewY(-12deg);
          transform-origin: 0;
          background-image: linear-gradient(-180deg, #3ecf8e 0%, #72e9c1 100%);
        `}
      />
      <div
        css={css`
          padding: 100px 0;
        `}
      >
        <Container
          css={css`
            align-items: start;
            ${containerCss}
          `}
        >
          <div
            css={css`
              padding: 0 0 50px;
            `}
          >
            {renderText(viewport)}
          </div>
          {viewport.isNarrowPlus && renderPicture()}
        </Container>
      </div>
    </header>
  )
}

export { Header }
