import styled from "@emotion/styled"

export const Button = styled.button`
  outline: 0;
  border: 0;
  background: #6772e5;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  color: #fff;
  font-size: 15px;
  padding: 9px 33px;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
`
