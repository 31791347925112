import React from "react"
import styled from "@emotion/styled"
import { Link } from "gatsby"
import Container from "./container"
import css from "@emotion/css"
import LogoFooter from "../images/logo-footer.svg"
import { highlight } from "./highlight"
import { useViewport } from "../hooks/use-viewport-width"

const FooterContainer = styled.div`
  position: relative;
  overflow: hidden;
`

const FooterMenu = styled.ul`
  list-style-type: none;
  font-size: 14px;
  line-height: 2em;
  color: #737376;
  padding: 0;
`

const FooterMenuItem = styled.li``

const FooterCopyright = styled.div`
  padding: 20px 0;
  color: #737376;
  font-size: 12px;
`

export const Footer = () => {
  const { isExtraNarrowPlus } = useViewport()
  return (
    <FooterContainer>
      <img
        css={css`
          position: absolute;
          right: -50px;
          bottom: -50px;
          z-index: -1;
        `}
        src={LogoFooter}
        alt="Footer Logo"
      />

      <Container
        css={css`
          height: 100%;
          flex-direction: column;
          justify-content: space-between;
        `}
      >
        <div
          css={css`
            display: flex;
            ${!isExtraNarrowPlus ? "flex-direction: column;" : ""}
            width: 100%;
            height: 100%;
            padding: 100px 0;
            justify-content: space-between;
            align-items: top;
          `}
        >
          <FooterMenu>
            <FooterMenuItem
              css={css`
                color: #000;
              `}
            >
              <b>You can meet us mostly in:</b>
              <br />
              - Bratislava
              <br />
              - Prague
              <br />
              - Los Angeles
              <br />
            </FooterMenuItem>
            We have remote people all over the world.
          </FooterMenu>
          <FooterMenu>
            <Link to="/jobs?ref=footer">
              <FooterMenuItem css={highlight}>We're hiring</FooterMenuItem>
            </Link>
            <Link to="/contact?ref=footer">
              <FooterMenuItem>Contact us</FooterMenuItem>
            </Link>
          </FooterMenu>
        </div>

        <FooterCopyright>
          All rights reserved © 2019 Crispy Apps s.r.o.
        </FooterCopyright>
      </Container>
    </FooterContainer>
  )
}
